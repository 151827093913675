<template>
  <v-container fluid>
    <v-row>
      <v-col md="12">
        <div class="subtitle-2 text-center">
          <v-btn rounded>2020 </v-btn>
        </div>
      </v-col>
      <v-expansion-panels
        flat
        hover
        tile
        light
        multiple
        accordion
        v-model="tabsOpen"
      >
        <v-expansion-panel v-for="(month, mi) in months" :key="mi">
          <v-expansion-panel-header>
            <span class="month_names">{{ month.name }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col v-for="i in month.posts" :key="i" lg="4">
                <PostCard
                  :id="i + mi"
                  :type="type"
                  :like="Math.floor(Math.random() * 100)"
                  :share="Math.floor(Math.random() * 100)"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col>
          <div class="subtitle-2 text-center">
            <v-btn rounded text>Load More</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import PostCard from "@/components/Marketing/PostCard";
export default {
  components: {
    PostCard,
  },
  mounted() {
    if (typeof this.$route.params.type != "undefined") {
      this.type = this.$route.params.type;
    }
  },
  data() {
    return {
      months: [
        { name: "September", posts: 4 },
        { name: "August", posts: Math.floor(Math.random() * 20) },
        { name: "July", posts: Math.floor(Math.random() * 20) },
        { name: "June", posts: Math.floor(Math.random() * 20) },
        { name: "May", posts: Math.floor(Math.random() * 20) },
        { name: "April", posts: Math.floor(Math.random() * 20) },
        { name: "March", posts: Math.floor(Math.random() * 20) },
      ],
      type: null,
      tabsOpen: [0],
    };
  },
};
</script>

<style scoped>
.v-expansion-panel-header--active {
  color: #00b0af;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.status_button_navigation .v-btn {
  padding: 0;
  height: 30px;
  min-width: 54px;
  font-size: 0.7rem;
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}
.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}
.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}
</style>
