<template>
  <v-card class="mx-auto">
    <v-list-item>
      <v-list-item-avatar>
        <v-img
          v-if="type == 'krews'"
          src="@/assets/images/social_media/krews.png"
          contain
        ></v-img>
        <v-img
          v-else-if="type == 'instagram'"
          src="@/assets/images/social_media/instagram.png"
          contain
        ></v-img>
        <v-img
          v-else-if="type == 'facebook'"
          src="@/assets/images/social_media/facebook.png"
          contain
        ></v-img>
        <v-img
          v-else-if="type == 'twitter'"
          src="@/assets/images/social_media/twitter.png"
          contain
        ></v-img>
        <v-img
          v-else-if="type == 'snapchat'"
          src="@/assets/images/social_media/snapchat.png"
          contain
        ></v-img>
        <v-img
          v-else-if="type == 'youtube'"
          src="@/assets/images/social_media/youtube.png"
          contain
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>Lorem ipsum dolor sit amet</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-img
      :src="`https://picsum.photos/500/300?image=${id * 5 + 10}`"
      :lazy-src="`https://picsum.photos/10/6?image=${id * 5 + 10}`"
      height="194"
    ></v-img>

    <v-card-text>
      <v-list-item-subtitle>
        <v-icon color="grey" small>mdi-clock-outline</v-icon>
        {{ date | timeStamp }}
      </v-list-item-subtitle>
      Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text color="#062b48">
        {{ like }} <span class="caption">Likes</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="#062b48">
        {{ share }} <span class="caption">Shares</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    type: { type: String, default: "krews" },
    date: { type: String, default: "2020-08-12 22:45:23" },
    id: { type: Number, default: 1 },
    like: { type: Number, default: 1 },
    share: { type: Number, default: 1 },
  },
};
</script>

<style></style>
